<div class="invoice-review">
  <p-dialog
  header="{{ 'pm.Batch_Update_Projects' | translate }} - {{selectedReviewItems.length}} {{'selected' | translate}}"
  [(visible)]="showBatchUpdate" [modal]="true" [style]="{width: '35vw'}" [maximizable]="false"
  [contentStyle]="{overflow: 'visible'}" showEffect="fade" (onHide)="onCloseBatchUpdate();" [baseZIndex]="1"
  [draggable]="true" [resizable]="false">
  <form role="form" [formGroup]="batchUpdateForm" #formDirective="ngForm" novalidate>
      <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
          <div class="p-col-12 p-formgroup-inline">
              <div class="p-col-6 p-mt-2">
                  <p-checkbox formControlName="isEmailTemplate" [binary]="true"
                      label="{{ 'billing-invoicing.invoice-template' | translate }}" class="label-text">
                  </p-checkbox>
              </div>
              <div class="p-col-6">
                  <p-dropdown  formControlName="EmailTemplate"
                      [disabled]="!batchUpdateForm.controls['isEmailTemplate'].value"
                      [options]="templateTypes" [autoWidth]="false" [style]="{'width':'100%'}"
                      panelStyleClass="invoice-review-dropdown-panel"
                      [ngModelOptions]="{standalone: true}" appendTo="body"></p-dropdown>
              </div>
          </div>
          <div class="p-col-12 p-formgroup-inline p-mt-3">
              <div class="p-col-6 p-mt-2">
                  <p-checkbox formControlName="isFinalizeAction" [binary]="true"
                      label="{{ 'FinalizeAction' | translate }}" class="label-text">
                  </p-checkbox>
              </div>
              <div class="p-col-6">
                  <p-dropdown formControlName="FinalizeAction"  [disabled]="!batchUpdateForm.controls['isFinalizeAction'].value"
                      panelStyleClass="invoice-review-dropdown-panel"
                      [options]="finalizeActions" [autoWidth]="false"
                      [style]="{'width':'100%'}" appendTo="body">
                  </p-dropdown>
              </div>
          </div>
      </div>
  </form>
  <ng-template pTemplate="footer">
      <button type="button" pButton pRipple icon="fal fa-times" (click)="onCloseBatchUpdate()"
          label="{{ 'cancel_button' | translate }}"></button>
      <button type="button" pButton pRipple icon="fal fa-check" label="{{ 'update-all' | translate }}"
          [disabled]="!isFormValid || (!batchUpdateForm.controls['isEmailTemplate'].value && !batchUpdateForm.controls['isFinalizeAction'].value)" (click)="saveBatchUpdate()"></button>
  </ng-template>
</p-dialog>
    <div class="layout-content p-pt-0">
        <!-- buttons -->
        <span class="pull-right p-mr-3 clientform-btn-top">
            <button pButton pRipple label="{{'Save-All' | translate}}" icon="fal fa-save"
                class="p-button-sm p-px-2 p-mr-2" (click)="updateAll();" [disabled]="(!isUpdateAllenabled)"></button>
            <button pButton pRipple label="{{'preview' | translate}}" icon="fal fa-print"
                class="p-button-sm p-px-2 p-mr-2" (click)="newPreviewInvoices(false);"
                [disabled]="(selectedReviewItems.length == 0)"></button>
            <button pButton pRipple label="{{'finalize' | translate}}" icon="fal fa-check"
                class="p-button-sm p-px-2 p-mr-2" (click)="finalizeAll();" [disabled]="(selectedReviewItems.length == 0)">
            </button>
            <!-- <button pButton pRipple label="{{'Reverse-Selected' | translate}}" icon="fal fa-undo"
                class="p-button-sm p-px-2 p-mr-2" [disabled]="selectedReviewItems.length == 0" (click)="batchReverseInvoices()"></button> -->
            <button pButton pRipple label="{{'attach-invoice-memo' | translate}}" icon="fal fa-file-plus"
                 class="p-button-sm p-px-2 p-mr-2" (click)="attachInvoiceMemo();"
                [disabled]="(selectedReviewItems.length == 0)"></button>

      </span>
      <!-- table -->
      <div class="p-grid">
          <div class="p-col-12">
              <div class="card p-mx-3 p-pt-0">
                  <p-table #dt [rowHover]="true" [(selection)]="selectedReviewItems" [value]="reviewDataSource" [columns]="selectedColumns"
                      [responsive]="true" dataKey="BillingHeaderID" selectionMode="single" [filterDelay]="0"
                      styleClass="p-datatable-hoverable-rows p-datatable-sm p-datatable-responsive-demo"
                      sortField="ClientName" (onColReorder)="onColReorder($event)" [reorderableColumns]="true"
                      [globalFilterFields]="globalFilterColumns"
                      [resizableColumns]="true" (onFilter)="onFilter($event)"
                      (onRowSelect)="checkUncheckExpensesRow($event,true)"
                      (onRowUnselect)=" checkUncheckExpensesRow($event,false)" [pageLinks]="3" [rows]="10"
                      [scrollable]="true" [scrollHeight]="'calc(90vh - 350px)'" [virtualScroll]="true">
                      <ng-template pTemplate="caption">
                          <div class="table-header">
                              <div class="p-grid">
                                  <div class="p-col-6">
                                      <span class="p-input-icon-left">
                                          <i class="fal fa-search"></i>
                                          <input #searchValue pInputText type="text"
                                              (input)="dt.filterGlobal($event.target.value, 'contains')"
                                              placeholder="{{'globalsearch' | translate}}" />
                                      </span>
                                      <span>
                                          <button pButton pRipple type="button" icon="pi pi-times"
                                              class="p-button-rounded p-button-danger p-button-text"
                                              (click)="dt.reset();clearSearchFilter()"
                                              pTooltip="{{'clear' | translate}}"></button>
                                      </span>
                                      <p-message severity="success" *ngIf="reviewDataSource.length > 0"
                                          text="{{filteredItemsSize > 0 ? filteredItemsSize : reviewDataSource.length}} {{'records_found' | translate}}." styleClass="p-ml-2">
                                      </p-message>
                                  </div>
                                  <div class="p-col-6 p-text-right">
                                      <span *ngIf="selectedReviewItems.length > 0" class="p-mr-3">
                                          <label class="label-text p-mr-2 p-mb-2" style="display: inline-block;">{{
                                              'pm.batch_options' | translate }}</label>
                                          <button type="button" pButton pRipple icon="pi pi-chevron-down"
                                              (click)="menu.toggle($event)"></button>
                                      </span>
                                      <p-menu appendTo="body" #menu [popup]="true" styleClass="button-list"
                                          [model]="buttonsDeleteList">
                                      </p-menu>
                                      <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"  styleClass="p-ml-3"
                                      selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" (onChange)="onChangeSelectedCols($event)"
                                      placeholder="Choose Columns"></p-multiSelect>

                                      <!-- Export CSV and PDF -->

                                    <button  *ngIf="reviewDataSource.length > 0" type="button" pButton pRipple icon="pi pi-file-o"
                                      (click)="exportCSVFile('all')"
                                      class="p-mx-2" pTooltip="Export CSV of the current page" tooltipPosition="bottom"

                                      >
                                     </button>
                                    <button (click)="exportPdf('all')"  *ngIf="reviewDataSource.length > 0" type="button" pButton pRipple icon="pi pi-file-pdf"
                                        class="p-button-warning p-mr-2" pTooltip="Export PDF of the current page"
                                        tooltipPosition="bottom">
                                    </button>

                                  </div>
                              </div>
                          </div>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th class="width-3p p-text-left">
                                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                              </th>
                              <th class="width-9p p-text-center">{{'action' | translate}}</th>
                              <th class="width-9p p-text-left" pSortableColumn="InvoiceDate">
                                  {{'Accounts.invoice-date' | translate}}
                                  <p-sortIcon field="InvoiceDate"></p-sortIcon>
                              </th>
                              <th class="width-7p p-text-left" pSortableColumn="InvoiceNumber">{{'Invoice' | translate}} #
                                  <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                              </th>
                              <th class="width-15p p-text-left" pSortableColumn="ClientName">{{'client.name' |
                                  translate}}
                                  <p-sortIcon field="ClientName"></p-sortIcon>
                              </th>
                              <th class="width-8p p-text-center" pTooltip="{{'invoice-memo' | translate}}"
                                  tooltipPosition="top">{{'Memo' | translate}}</th>
                              <!-- <th pSortableColumn="StaffName" class="width-15p p-text-left">
                                  {{'Billing_Partner' | translate}}
                                  <p-sortIcon field="StaffName"></p-sortIcon>
                              </th>
                              <th pSortableColumn="GroupDescription" class="width-13p p-text-left">
                                  {{'group-description' | translate}}
                                  <p-sortIcon field="GroupDescription"></p-sortIcon>
                              </th> -->
                             <!--  <th class="width-8p p-text-left">{{'client.billing_partner_name' | translate}}</th>
                              <th class="width-8p p-text-left">{{'exports.Billing_Partner_ID' | translate}}</th>
                              <th class="width-9p p-text-left" pSortableColumn="InvoiceDate">
                                  {{'Accounts.invoice-date' | translate}}
                                  <p-sortIcon field="InvoiceDate"></p-sortIcon>
                              </th>
                              <th class="width-7p p-text-left" pSortableColumn="InvoiceNumber">{{'Invoice' |
                                  translate}} #
                                  <p-sortIcon field="InvoiceNumber"></p-sortIcon>
                              </th>
                              <th class="width-8p p-text-right">{{'client.payments' | translate}}</th> -->
                              <th *ngFor="let col of columns" pReorderableColumn [pSortableColumn]="col.field" class="{{col.class}}">
                                  <div [ngClass]="col.canFilter ? 'p-d-flex p-jc-between' : ''">
                                      <div class="p-mt-1">
                                          {{col.header}}
                                          <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                      </div>
                                      <p-columnFilter *ngIf="col.canFilter" [type]="col.type || 'text'" [field]="col.field"
                                          display="menu"></p-columnFilter>
                                  </div>
                              </th>
                              <th class="width-7p p-text-right" pTooltip="{{'Accounts.invoice-amount' | translate}}"
                                  tooltipPosition="top" pSortableColumn="InvoiceAmountComputed">{{'amount' | translate}}
                                  <p-sortIcon field="InvoiceAmountComputed"></p-sortIcon>
                              </th>
                              <th class="width-18p p-text-left">{{'billing-invoicing.invoice-template' | translate}}
                              </th>
                              <th class="width-12p p-text-left">{{'FinalizeAction' | translate}}</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-i="rowIndex" let-columns="columns">
                          <tr class="cursor-hand">
                              <td class="width-3p p-text-left">
                                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                              </td>
                              <td class="width-9p p-text-center">
                                  <div class="p-text-center">
                                      <i class="fal fa-undo ic-blue p-pr-2"
                                          pTooltip="{{'Reverse-Invoice' | translate}}" tooltipPosition="top"
                                          (click)="reverseInvoice(rowData)"></i>
                                      <i class="fal fa-history p-mx-2 ic-red p-pr-2"
                                          pTooltip="{{'Time-Report' | translate}}" tooltipPosition="top"
                                          (click)="previewTimeHistoryReport(rowData)"></i>
                                      <i class="fal fa-pencil ic-green p-pr-2" pTooltip="{{'Edit' | translate}}"
                                          tooltipPosition="top" (click)="editInvoice(rowData)"></i>
                                      <i class="fal fa-asterisk p-ml-2 ic-red" pTooltip="{{'Modified' | translate}}"
                                          tooltipPosition="top" *ngIf="rowData['IsColumnChanges']"></i>
                                  </div>
                              </td>
                              <td
                                class="
                                  width-9p
                                  p-text-left
                                "
                               >
                                {{
                                  rowData[ 'InvoiceDate' ] | date:'MM/dd/yyyy'
                                }}
                              </td>
                              <td class="width-7p p-text-left">
                                  {{rowData.InvoiceNumber}}
                              </td>
                              <td class="width-15p p-text-left p-text-nowrap p-text-truncate"
                                  pTooltip="{{rowData.ClientName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                  tooltipPosition="top">
                                  {{rowData.ClientName}}
                              </td>
                              <td class="width-8p p-text-center">
                                  <i pTooltip="{{rowData.DescriptionShort}}" class="fal fa-file-invoice"
                                      tooltipStyleClass="tooltip-width-450" tooltipPosition="top"></i>
                              </td>
                              <!-- <td class="width-15p p-text-left"
                                  pTooltip="{{rowData.StaffName}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                  tooltipPosition="top">
                                  {{rowData.StaffName}}
                              </td>
                              <td class="width-13p p-text-left"
                                  pTooltip="{{rowData.GroupDescription}}" showDelay="800" tooltipStyleClass="tooltip-no-wrap"
                                  tooltipPosition="top">
                                  {{rowData.GroupDescription}}
                              </td> -->
                              <!-- <td class="width-8p p-text-right">
                                  {{rowData.StaffName}}
                              </td>
                              <td class="width-8p p-text-right">
                                  {{rowData.BillingPartnerID}}
                              </td>
                              <td class="width-8p p-text-right">
                                  {{rowData.TotalPayments | currency:'USD':'symbol':'1.2-2'}}
                              </td> -->
                              <td *ngFor="let col of columns" class="{{col.class}}" pTooltip="{{rowData[col.field]}}"
                                  tooltipPosition="top" showDelay="800">
                                  {{ formatValue(rowData[col.field], col.type) }}
                              </td>
                              <td class="width-7p p-text-right">
                                  {{ rowData['InvoiceAmountComputed'] | currency:'USD':'symbol':'1.2-2'}}
                              </td>
                              <td pEditableColumn class="width-18p p-text-nowrap p-text-left">
                                  <p-cellEditor>
                                      <ng-template pTemplate="input">
                                          <p-dropdown [(ngModel)]="rowData['InvoiceTemplate']"
                                              [options]="templateTypes" [autoWidth]="false" [style]="{'width':'100%'}"
                                              panelStyleClass="invoice-review-dropdown-panel"
                                              [ngModelOptions]="{standalone: true}" appendTo="body"
                                              (onChange)="handleDropSelectClick($event,rowData)"></p-dropdown>
                                      </ng-template>
                                      <ng-template pTemplate="output">
                                          <span pTooltip="{{rowData.InvoiceTemplateName}}"
                                              showDelay="800" tooltipStyleClass="tooltip-no-wrap">{{rowData.InvoiceTemplateName}}</span>
                                      </ng-template>
                                  </p-cellEditor>
                              </td>
                              <td pEditableColumn style="overflow:visible;" class="width-12p p-text-left">
                                  <p-cellEditor>
                                      <ng-template pTemplate="input">
                                          <p-dropdown [(ngModel)]="rowData['FinalizeAction']"
                                              panelStyleClass="invoice-review-dropdown-panel"
                                              [options]="finalizeActions" [autoWidth]="false"
                                              [style]="{'width':'100%'}" [ngModelOptions]="{standalone: true}"
                                              appendTo="body" (onChange)="doFinalizeActions($event,rowData)">
                                          </p-dropdown>
                                      </ng-template>
                                      <ng-template pTemplate="output">
                                          <div>{{rowData['FinalizeAction']}}</div>
                                      </ng-template>
                                  </p-cellEditor>
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="footer" let-columns>
                          <tr *ngIf="(filteredItemsSize > 0) || (filteredItemsSize == -1 && reviewDataSource.length > 0)">
                              <td class="width-3p p-text-left"></td>
                              <td class="width-9p p-text-center"></td>
                              <td class="width-9p"></td>
                              <td class="width-7p p-text-left"></td>
                              <td class="width-15p p-text-left"></td>
                              <td class="width-8p p-text-right p-text-bold ic-red">{{'Totals' | translate}}</td>
                              <td *ngFor="let col of columns" class="{{col.class}}">
                                  <span *ngIf="col.field === 'TotalPayments'" class="p-text-right p-text-bold ic-red">
                                      {{ totalPaymentsAmount | currency:'USD':'symbol':'1.2-2'}}
                                  </span>
                              </td>
                              <td class="width-7p p-text-right p-text-bold ic-red"> {{ totalAmount | currency:'USD':'symbol':'1.2-2' }}</td>
                              <td class="width-18p"></td>
                              <td class="width-12p"></td>
                              <!-- <td class="p-text-right p-text-bold ic-red width-30p">{{'Totals' | translate}}</td>
                              <td class="p-text-right p-text-bold ic-red width-8p">
                                  {{ totalPaymentsAmount | currency:'USD':'symbol':'1.2-2'}}
                              </td>
                              <td class="p-text-right p-text-bold ic-red width-18p">
                                  {{ totalAmount | currency:'USD':'symbol':'1.2-2' }}
                              </td>
                              <td class="width-30p"></td> -->
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                              <td colspan="10">
                                  <p-message severity="info" text="{{'data_not_found' | translate}}."
                                      styleClass="p-px-2"></p-message>
                              </td>
                          </tr>
                      </ng-template>
                  </p-table>
              </div>
          </div>
      </div>
  </div>
  <p-dialog header="Memo" [(visible)]="dialogDisplay" [modal]="true" [style]="{width: '55vw','min-height': '100%'}"
    [maximizable]="false" showEffect="fade" [baseZIndex]="10000" [draggable]="true" [resizable]="false">
    <div class="p-col-12 p-py-0 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0 ">
            <label for="date" class="label-text">
                <span>{{'Invoice-Message' | translate}}</span>
            </label>
            <span class="pull-right cursor-hand p-mr-1 ic-red" (click)="enableEditor()">{{btnText}}</span>
            <ejs-richtexteditor #invoiceEditor [height]="'calc(38vh - 100px)'" [readonly]="IsDataReadOnly"
                [(value)]="invoiceOpt.InvoiceMessage">
            </ejs-richtexteditor>
        </div>
    </div>
    <div class="p-col-12 p-py-0 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0">
            <label for="Top Memo" class="label-text">{{'top-memo' | translate}}</label>
            <ejs-richtexteditor #topEditor [height]="'calc(38vh - 100px)'" [(value)]="topMemoValue" class="top-bottom-memo"
                (change)="onTopBottomEditorChange($event)">
            </ejs-richtexteditor>
        </div>
    </div>
    <div class="p-col-12 p-py-0 richtext">
        <div class="p-field p-col-12 p-py-0 p-my-0">
            <label for="Footer Message" class="label-text">{{'bottom-memo' | translate}}</label>
            <ejs-richtexteditor #footerEditor [height]="'calc(38vh - 100px)'" [(value)]="bottomMemoValue" class="top-bottom-memo"
                (change)="onTopBottomEditorChange($event)">
            </ejs-richtexteditor>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="(!IsTopBottomModified)"
            (click)="saveMemos()" label="{{'save_button' | translate}}"></button>
        <button type="button" pButton pRipple icon="fal fa-times"
            (click)="dialogDisplay=false;IsTopBottomModified=false;" label="{{'cancel_button' | translate}}"></button>
    </ng-template>
  </p-dialog>
  <p-dialog header="{{'Edit' | translate}} " [(visible)]="isEditDialogOpen " [modal]="true " [style]="{width: '50vw'} "
    [maximizable]="false " showEffect="fade " [baseZIndex]="10000 " [draggable]="true " [resizable]="false ">
    <div class="p-fluid p-formgrid p-grid p-m-1 ">
        <div class="p-col-12 ">
            <div class="card ">
                <h4>{{'Empty-Page' | translate}}</h4>
                <p>{{'Empty-Page-tooltip' | translate}}</p>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer ">
        <button type="button " pButton pRipple icon="fal fa-check " (click)="isEditDialogOpen=false "
            label="{{'Yes' | translate}} "></button>
        <button type="button " pButton pRipple icon="fal fa-times " (click)="isEditDialogOpen=false "
            label="{{'No' | translate}} "></button>
    </ng-template>
  </p-dialog>
  <p-sidebar [(visible)]="visibleSidebar " [closeOnEscape]="false " [dismissible]="false " [showCloseIcon]="false" [fullScreen]="true "
    [style]="{ 'background': '#8e8f92'} ">
    <div class="card ">
        <div class="pull-right p-pr-4">
            <button type="button" pButton pRipple icon="fal fa-times p-mr-2" label="{{ 'close' | translate }}"
            (click)="resetDialog();clearPreviewPrint(); "></button>
        </div>
        <div>
            <p-tabView #tabView [(activeIndex)]="activeTab" (onChange)="handleChange($event, tabView)">
                <p-tabPanel *ngIf="narrativeCount > 0" [dataKey]="'narrative'"
                            header="{{'Narrative-Invoices' | translate}} ( {{narrativeCount}} )">
                  <ngx-extended-pdf-viewer  *ngIf="pdfNarrativePdfUrl && activeTab === 0" 
                      [delayFirstView]="true"   [src]="pdfNarrativePdfUrl + '?' + refreshKey" 
                      id="Client Invoices 1" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
                
                <p-tabPanel *ngIf="detailCount > 0" [dataKey]="'detail'"
                            header="{{'Detail-Invoices' | translate}} ( {{detailCount}} )">
                  <ngx-extended-pdf-viewer  *ngIf="pdfDetailPdfUrl && activeTab === 1" 
                      [delayFirstView]="true"    [src]="pdfDetailPdfUrl + '?' + refreshKey" 
                      id="Client Invoices 2" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
                
                <p-tabPanel *ngIf="simpleCount > 0" [dataKey]="'simple'"
                            header="{{'Simple-Narrative-Invoices' | translate}} ( {{simpleCount}} )">
                  <ngx-extended-pdf-viewer  *ngIf="pdfSimplePdfUrl && activeTab === 2"
                      [delayFirstView]="true" [src]="pdfSimplePdfUrl + '?' + refreshKey" 
                      id="Client Invoices 3" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
                
                <p-tabPanel *ngIf="narrativeSummaryCount > 0" [dataKey]="'narrative-summary'"
                            header="{{'Narrative-Summary-Invoices' | translate}} ( {{narrativeSummaryCount}} )">
                  <ngx-extended-pdf-viewer   *ngIf="pdfNarrativeSummaryPdfUrl && activeTab === 3" 
                      [delayFirstView]="true" [src]="pdfNarrativeSummaryPdfUrl + '?' + refreshKey" 
                      id="Client Invoices 4" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
                
                <p-tabPanel *ngIf="simpleCountNoRemit > 0" [dataKey]="'simple-no-remit'"
                            header="{{'SimpleNoRemit' | translate}} ( {{simpleCountNoRemit}} )">
                  <ngx-extended-pdf-viewer  *ngIf="pdfSimpleNarrativeNoRemitPdfUrl && activeTab === 4" 
                      [delayFirstView]="true"  [src]="pdfSimpleNarrativeNoRemitPdfUrl + '?' + refreshKey" 
                      id="Client Invoices 5" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
                
                <p-tabPanel *ngIf="narrativeWithActivityCount > 0" [dataKey]="'narrative-with-activity'"
                            header="{{'Narrative-With-Activity' | translate}} ( {{narrativeWithActivityCount}} )">
                  <ngx-extended-pdf-viewer *ngIf="pdfNarrativeWithActivityPdfUrl && activeTab === 5" 
                      [delayFirstView]="true"   [src]="pdfNarrativeWithActivityPdfUrl + '?' + refreshKey" 
                      id="Client Invoices 6" type="application/pdf" [height]="'85vh'" [useBrowserLocale]="true"
                      [printResolution]="300" [showSecondaryToolbarButton]="false" [showBookmarkButton]="false"
                      [showBorders]="true" [showPresentationModeButton]="false" [showRotateButton]="false"
                      [showSidebarButton]="false" [showOpenFileButton]="false" [zoom]="125">
                  </ngx-extended-pdf-viewer>
                </p-tabPanel>
              </p-tabView>
        </div>
    </div>
  </p-sidebar>
  
