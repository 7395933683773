<p-dialog
  header="{{ 'time-sheet-entry' | translate }}"

  showEffect="fade"

  [(visible)]="displayTimeEntryModal"

  [modal]="true"
  [style]="
    {
      width: '51vw',
      'max-height': '98%'
    }
  "
  [contentStyle]="{overflow: 'visible'}"
  [maximizable]="false"
  [baseZIndex]="1"
  [resizable]="false"
  [draggable]="true"

  (onHide)="closeTimeEntryForm(true);"
>
    <div class="p-fluid p-formgrid p-grid p-m-1 common-dialog">
        <form #timeEntryMainForm="ngForm" class="p-col-12">
            <div class="p-col-12">
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'staff' | translate }}
                            <i tooltipPosition="top" pTooltip="{{ 'timesheetentry.staff-tooltip' | translate }}"
                                tooltipStyleClass="tooltip-width-300"
                                class="fa fa-question-circle ic-blue cursor-hand"></i>
                        </label>
                        <p-dropdown [options]="staffList" [(ngModel)]="staffID" name="staffID" #staff="ngModel"
                            (onChange)="selectEditStaff($event,false)" panelStyleClass="dialog-dropdown"
                            placeholder="{{ 'select' | translate }} {{ 'staff' | translate }}" required></p-dropdown>
                    </div>
                    <div
                      class="p-field p-col-2"
                    >
                      <label
                        class="label-text"
                      >
                        {{ 'work-date' | translate }}
                      </label>
                      <p-calendar
                        name="Ddate"

                        yearRange="2000:2090"

                        [monthNavigator]="true"
                        [yearNavigator]="true"

                        [(ngModel)]="tse.displayDate"

                        (onSelect)="
                          verifySystemLocking( $event );
                          validateWorkDate( );
                        "
                      >
                      </p-calendar>
                    </div>
                    <div class="p-field p-col-2">
                        <label class="label-text">{{ 'user.Start-Time' | translate }}</label>
                        <p-calendar autocomplete="off" [timeOnly]="true" [(ngModel)]="tse.TimeStart" name="timeStart"
                            (onSelect)="changeStartTime()" [hourFormat]="12" [stepMinute]="1"
                            (onBlur)="changeStartTime()" #timeStart="ngModel" required>
                        </p-calendar>
                    </div>

                    <div
                      class="
                        p-col-2
                        p-field

                        start-time
                      "
                    >
                      <label
                        class="
                          p-mr-0
                          p-pr-0
                        "
                      >
                        <span
                          class="
                            label-text
                          "
                        >
                          {{ 'user.End-Time' | translate }}
                        </span>

                        <span
                          class="
                            p-mx-2

                            ic-orange
                            cursor-hand
                          "

                          pTooltip="{{ 'get-current-time.tooltip' | translate }}"
                          tooltipPosition="top"

                          (click)="
                            getEndTime( );
                          "
                        >
                          <i
                            class="
                              fal
                              fa-clock

                              ic-red
                            "
                          >
                          </i>
                        </span>

                        <span
                          class="
                            ic-orange
                            cursor-hand
                          "

                          pTooltip="{{ 'clear-time' | translate }}"
                          tooltipPosition="top"

                          (click)="
                            tse.TimeStop=null;
                            tse.ElaspedTime=0;
                            formatActuallTime( );
                          "
                        >
                          <i
                            class="
                              fal
                              fa-times

                              ic-red
                            "
                          >
                          </i>
                        </span>
                      </label>

                      <p-calendar
                        autocomplete="off"

                        name="timeStop"
                        tabindex="-1"

                        #timeStop="ngModel"

                        [timeOnly]="true"
                        [hourFormat]="12"
                        [stepMinute]="1"

                        [(ngModel)]="tse.TimeStop"

                        (onSelect)="changeEndTime()"
                        (onBlur)="changeEndTime()"
                        (onFocus)="onEndTimeFocus()"
                      >
                      </p-calendar>
                    </div>
                </div>

                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'client' | translate }}</label>
                        <p-autoComplete #autoComplete 
                            [(ngModel)]="selClient" 
                            autocomplete="off" 
                            [suggestions]="filteredClients"
                            (completeMethod)="filterClientsDebounced($event)" 
                            field="ClientName"
                            [disabled]="tse.MarkSlipsBilled || tse.isDisable || tse.isSystemLocked"
                            (onSelect)="handleSelectClick($event.value, true)"
                            [dropdown]="true" 
                            ngDefaultControl 
                            placeholder="{{ 'type_client_name' | translate }}"
                            name="selclientID" 
                            #selclientID="ngModel" 
                            required  
                            (onKeyUp)="handleClientKeyPress($event)">
                          <ng-template let-client pTemplate="item">
                            <div>{{client.ClientName}}{{ client.ClientNumber ? ' - ' + client.ClientNumber : '' }}</div>
                          </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="p-field p-col-6" *ngIf="companyGlobalSetting.isWorkLocationRequired">
                        <label class="required-field label-text">{{ 'Work-Location' | translate }}</label>
                        <p-dropdown [options]="workLocationsList" [(ngModel)]="tse.WorkLocation" name="workLocation"
                            [filter]="true" id="workLocation" #workLocation="ngModel" (focus)="$event.target.select()"
                            placeholder="Search Location" required></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'engagement' | translate }}</label>
                        <button pButton pRipple (click)="addEngagementPopUp()" [disabled]="selClient==null" tooltipPosition="top"
                            pTooltip="{{ 'add_engagement' | translate }}" tooltipStyleClass="tooltip-no-wrap" type="button"
                            icon="fal fa-plus-circle"
                            class="p-button-rounded p-button-danger p-button-text pull-right height-button">
                        </button>
                        <p-dropdown *ngIf="projectsList && projectsList.length > 0" [options]="projectsList" panelStyleClass="dialog-dropdown" [(ngModel)]="tse.ProjectMasterID"
                            name="itemProjectSelected" [filter]="true" id="itemProjectSelected"
                            #itemProjectSelected="ngModel" [disabled]="selClient==null || tse.isDisable || (isEditTS && tse.ddmProjectHeaderID)"
                            (onChange)="handleEngagementSelectClick($event, true)"
                            placeholder="{{ 'select-engagement' | translate }}" required></p-dropdown>
                    </div>
                    <div class="p-field p-col-6">
                        <label class="required-field label-text">{{ 'activity' | translate }}</label>
                        <button pButton pRipple (click)="addActivity()" tooltipPosition="top"
                            pTooltip="Add Activity" tooltipStyleClass="tooltip-no-wrap" type="button"
                            icon="fal fa-plus-circle" [disabled]="selClient==null"
                            class="p-button-rounded p-button-danger p-button-text pull-right height-button">
                        </button>
                        <p-dropdown [options]="activityList" panelStyleClass="dialog-dropdown" [(ngModel)]="tse.ServiceCodeID"
                            styleClass="timesheet-entry-activity" name="itemServiceCodeSelected" [filter]="true"
                            id="itemServiceCodeSelected" #itemServiceCodeSelected="ngModel" [disabled]="selClient==null"
                            (onChange)="handleActivitySelectClick($event,true,true);"
                            placeholder="{{ 'select' | translate }} {{ 'activity' | translate }}" required></p-dropdown>
                    </div>
                </div>
                <div class="p-formgroup-inline" *ngIf="ddmSettings?.isTrackingTimebyProjects">
                    <div class="p-field p-col-6"  *ngIf="tse.ProjectMasterID && clientProjectsList.length > 0">
                        <label class="label-text" [ngClass]="{'required-field': clientProjectsList.length > 0, '': clientProjectsList.length == 0}">{{ 'client.project' | translate }}</label>
                        <p-autoComplete [(ngModel)]="selProjectHeaderID" autocomplete="off" [suggestions]="clientProjectsList"
                            (completeMethod)="filterClientProjects($event, 0)" field="TemplateName"
                            [disabled]="selClient==null || tse.isDisable || (isEditTS && tse.ddmProjectHeaderID) || !tse.ProjectMasterID"
                            (onSelect)="handleProjectSelectClick($event.value, 0);"
                            [dropdown]="true" ngDefaultControl placeholder="{{ 'select' | translate }} {{ 'project' | translate }}"
                            name="selDdmProjectHeaderID" #selDdmProjectHeaderID="ngModel" required>
                            <ng-template let-p pTemplate="item">
                                <div>{{ p.TemplateName  }}</div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="p-field p-col-6" *ngIf="selProjectHeaderID && clientProjectTasksList.length > 0">
                        <label class="required-field label-text">{{ 'client.project-task' | translate }}</label>
                        <p-autoComplete [(ngModel)]="selProjectDetailsID" autocomplete="off" [suggestions]="clientProjectTasksList"
                            (completeMethod)="filterClientProjects($event, 1)" field="TaskDescription"
                            [disabled]="tse.ddmProjectHeaderID == null"
                            (onSelect)="handleProjectSelectClick($event.value, 1);"
                            [dropdown]="true" ngDefaultControl placeholder="{{ 'select' | translate }} {{ 'task' | translate }}"
                            name="selDdmProjectDetailsID" #selDdmProjectDetailsID="ngModel" required>
                            <ng-template let-t pTemplate="item">
                                <div>{{t.TaskDescription}}</div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
                <div class="p-formgroup-inline">
                    <div class="p-field p-col-3">
                        <div class="p-d-flex p-jc-between">
                            <label class="required-field label-text">{{ 'time.Actual' | translate }} {{ 'Time' |
                                translate }}</label>
                            <i class="fal fa-clock ic-red" *ngIf="actualTimeTooltip"
                                (mouseover)="showActualTimeToolTip=true;" (mouseleave)="showActualTimeToolTip=false;"
                                tooltipPosition="top" pTooltip="{{actualTimeTooltip}}"
                                tooltipStyleClass="tooltip-no-wrap"></i>
                        </div>
                        <input type="text" pInputText autocomplete="off" class="p-text-right" name="elaspedTime"
                            (change)="formatActuallTime()" autocomplete="off" [(ngModel)]="tse.ElaspedTime"
                            #elaspedTime="ngModel" (focus)="$event.target.select()"
                            [disabled]="(tse.TimeStart && tse.TimeStop)" required>
                    </div>
                    <div class="p-field p-col-3">
                        <div class="p-d-flex p-jc-between">
                            <label class="required-field label-text" *ngIf="isBillable">{{ 'billable-time' | translate
                                }}</label>
                            <label class="required-field label-text" *ngIf="!isBillable">{{ 'Non-Billable' | translate
                                }} {{ 'Time' | translate }} </label>
                            <i class="fal fa-clock ic-red" *ngIf="billableTimeTooltip"
                                (mouseover)="showBillableTimeToolTip=true;" pTooltip="{{billableTimeTooltip}}"
                                tooltipStyleClass="tooltip-no-wrap" tooltipPosition="top"
                                (mouseleave)="showBillableTimeToolTip=false;"></i>
                        </div>
                        <input type="text" pInputText autocomplete="off" class="p-text-right" name="totalTime"
                            (change)="calculateBillableAmount()" [(ngModel)]="tse.TotalTime" id="totalTime"
                            [disabled]="!billableTimeChk" (focus)="$event.target.select()" #totalTime="ngModel"
                            required>
                    </div>
                    <div class="p-col-2 p-mt-5" *ngIf="companyGlobalSetting.ActualTimeEdit">
                        <p-checkbox [binary]="true" label="{{ 'override-time' | translate }}" class="label-text"
                            [(ngModel)]="billableTimeChk" name="billableTimeChk"></p-checkbox>
                    </div>
                    <div class="p-col-2 p-mt-5">
                        <p-checkbox [binary]="true" label="{{ 'billable' | translate }}" class="label-text"
                            [(ngModel)]="tse.Billable" name="billable"></p-checkbox>
                    </div>
                    <div class="p-col-2 p-mt-5">
                        <p-checkbox [binary]="true" disabled="true" label="{{ 'approved' | translate }}"
                            class="label-text" [(ngModel)]="tse.Approved" name="approved"></p-checkbox>
                    </div>
                </div>
                <div
                  class="p-formgroup-inline"

                  *ngIf="
                        !companyGlobalSetting.HideBillRate
                    ||  (companyGlobalSetting.isRateShowAdmin && userPermissionType === 'Administrator')
                  "
                >
                  <div
                    class="p-field p-col-3"
                  >
                    <label
                      class="required-field label-text"
                      tooltipPosition="top"
                      pTooltip="Billable Rate"
                    >
                      {{ 'billable' | translate }} {{ 'rate' | translate }}
                    </label>
                    <input
                      pInputText

                      type="text"
                      autocomplete="off"
                      class="p-text-right"
                      name="billingRate"

                      #billingRate="ngModel"

                      [disabled]="!billingRateChk"

                      [(ngModel)]="tse.BillingRate"

                      (focus)="
                        $event.target.select( );
                      "
                      (blur)="
                        formatBillingRate( tse.BillingRate,'a' );
                        calculateBillableAmount( );
                      "
                      required
                    >
                  </div>
                  <div
                    class="p-field p-col-3"
                  >
                    <label
                      *ngIf="isBillable"
                      class="p-mr-0"
                    >
                      <span
                        class="label-text required-field"
                        tooltipPosition="top"
                        pTooltip="{{ 'billable-amount' | translate }}"
                      >
                        Amount
                      </span>
                      <span
                        *ngIf="
                              selClient
                          &&  !(
                                    selClient['MarkSlipsBilled']
                                &&  timeexp?.isAdminTimeZero
                              )
                        "

                        class="ic-red cursor-hand p-pl-1 p-text-nowrap p-text-truncate"
                        tooltipPosition="top"
                        pTooltip="{{isFlatFeeText}}"

                        (click)="
                          isFlatText( tse );
                        "
                      >
                        <u>
                          {{ isFlatFeeText }}
                        </u>
                      </span>
                    </label>
                    <label
                      *ngIf="
                            !isBillable
                        &&  (
                                  selClient
                              &&  !(
                                        selClient['MarkSlipsBilled']
                                    &&  timeexp?.isAdminTimeZero
                                  )
                            )
                      "

                      class="label-text required-field"
                    >
                      {{ 'Non-Billable-Amount' | translate }}
                    </label>
                    <input
                      pInputText

                      class="p-text-right"
                      type="text"
                      autocomplete="off"
                      maxlength="10"
                      name="standardAmount"

                      [disabled]="(selClient && (selClient['MarkSlipsBilled']  && timeexp?.isAdminTimeZero)) || isFlatFeeText === 'Flat Fee'"

                      [(ngModel)]="tse.StandardAmount"

                      #standardAmount="ngModel"
                      #standamt

                      (change)="
                        formateText( true );
                      "
                      (focus)="
                        $event.target.select( );
                      "
                      required
                    >
                  </div>
                  <div
                    *ngIf="companyGlobalSetting.BillableTimeEdit"

                    class="p-col-2 p-mt-5"
                  >
                    <p-checkbox
                      class="label-text"

                      name="billingRateChk"
                      label="{{ 'override-rate' | translate }}"

                      [binary]="true"

                      [(ngModel)]="billingRateChk"
                    >
                    </p-checkbox>
                  </div>
                  <div
                    *ngIf="budgetList?.length > 0"

                    class="p-col-4"
                  >
                    <label
                      class="label-text"
                    >
                      {{ 'budget.year' | translate }}
                    </label>
                    <p-dropdown
                      id="BudgetsID"
                      name="BudgetsID"
                      placeholder="{{ 'select' | translate }} {{ 'budget.year' | translate }}"
                      styleClass="p-mt-2"

                      #BudgetsID="ngModel"

                      [options]="budgetList"
                      [disabled]="selClient==null || !tse.ProjectMasterID"

                      [(ngModel)]="tse.BudgetsID"
                    >
                    </p-dropdown>
                  </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-ml-2">
                <label for="address" class="label-text required-field"
                    [ngClass]="{'required-field': tse.TimeMemoRequired, '': !tse.TimeMemoRequired}">{{
                    'work.description' | translate }}</label>
                    <app-mentions [(inputText)]="tse.Memo" (input)="onChangesDetection($event)"style="width: 100%; resize: vertical;"></app-mentions>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <button type="button" pButton pRipple icon="fal fa-file-alt" label="{{ 'Private-Memo' | translate }}"
            (click)="isShowMemo=!isShowMemo" class="pull-left"></button>
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="isSaving || isBillingRateFetching || !isFormValid" (click)="addTS('yes')"
            *ngIf="!isEditTS" label="{{ 'save_and_close' | translate }}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="isSaving || isBillingRateFetching || !isFormValid" (click)="addTS('new')"
            label="{{ 'save_and_new' | translate }}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="isSaving || isBillingRateFetching || !isFormValid" (click)="addTS('duplicate')"
            label="{{ 'save_and_duplicate' | translate }}" ></button>
        <button type="button" pButton pRipple icon="fal fa-check" [disabled]="isSaving || isBillingRateFetching || !isFormValid" (click)="updateTS('yes')"
            label="{{ 'update_button' | translate }}" *ngIf="isEditTS"
            ></button>
        <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'cancel_button' | translate }}"
             (click)="closeTEModal(true)"></button>
    </ng-template>
</p-dialog>
<p-sidebar [(visible)]="isShowMemo" [baseZIndex]="10001" position="bottom" styleClass="p-sidebar-md" [showCloseIcon]="false" >
    <div class="card">
        <div class="pull-right">
            <button type="button" pButton pRipple icon="fal fa-undo" label="{{ 'Clear' | translate }}" class="p-mr-3 p-mb-3"
                (click)="clearPrivateMemo()" [disabled]="!tse.PrivateMemo || tse.PrivateMemo.length == 0"></button>
            <button type="button" pButton pRipple icon="fal fa-times" label="{{ 'Close' | translate }}" class="p-mb-3"
                (click)="closePrivateMemo()"></button>
        </div>
        <div class="p-field p-col-12">
            <label class="label-text">{{ 'Private-Memo' | translate }}</label>
            <app-mentions [(inputText)]="tse.PrivateMemo" style="width: 100%; resize: vertical;" (input)="onChangesDetection($event)"></app-mentions>
        </div>
    </div>
</p-sidebar>
