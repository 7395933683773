import { Directive, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appKeydownCapture]'
})
export class KeydownCaptureDirective implements AfterViewInit {
  @Output() keydownEvent = new EventEmitter<KeyboardEvent>();

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const inputElement = this.el.nativeElement.querySelector('input');
    if (inputElement) {
      inputElement.addEventListener('keydown', (event: KeyboardEvent) => {
        this.keydownEvent.emit(event); // Emitimos el evento
      });
    }
  }
}
